import { render, staticRenderFns } from "./EditReception.vue?vue&type=template&id=13b45771"
import script from "./EditReception.vue?vue&type=script&lang=js"
export * from "./EditReception.vue?vue&type=script&lang=js"
import style0 from "./EditReception.vue?vue&type=style&index=0&id=13b45771&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports