<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title style="background-color: transparent !important">
        <h3>
          پرونده {{ userData.user.name }} ({{ userData.user.role }})
          {{ userData.user.natCode ? "/ " + userData.user.natCode : "" }}
        </h3>
      </v-card-title>
      <v-tabs
        background-color="transparent"
        vertical
        v-model="tab"
        :style="
          deviceType == 'mobile'
            ? 'border-bottom : 1px solid rgba(0, 0, 0, 0.1) !important'
            : ''
        "
      >
        <v-tab class="tab-title first-tab-item-br"> اطلاعات کارمند </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          ویرایش اطلاعات کارمند
        </v-tab>
        <v-tab
          v-if="
            userData.user.role == 'پذیرش درمانگاه' ||
            userData.user.role == 'پذیرش آزمایشگاه'
          "
          class="tab-title"
          style="border-radius: 0 !important"
        >
          خدمات
        </v-tab>
        <v-tab
          v-if="userData.user.role == 'پذیرش درمانگاه'"
          class="tab-title"
          style="border-radius: 0 !important"
        >
          ساعات کارکرد
        </v-tab>
        <v-tab class="tab-title" style="border-radius: 0 !important">
          لیست مرخصی‌ها</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >پرداختی‌های {{ userData.user.role }}</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >دریافتی های {{ userData.user.role }}</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >تسویه حساب</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >فیش حقوقی</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >معاینات بدو استخدام</v-tab
        >
        <v-tab class="tab-title" style="border-radius: 0 !important"
          >بایگانی فایل‌ها</v-tab
        >
        <v-tab class="tab-title last-tab-item-br">تغییر رمز عبور</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-info :job="'reception'" :userData="userData.user" />
            </v-card>
          </v-tab-item>
          <!--ویرایش اطلاعات کارمند -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <edit-person
                :job="'reception'"
                @getInfo="showReceptionInfo()"
                :userData="userData.user"
              />
            </v-card>
          </v-tab-item>
          <!-- خدمات -->
          <v-tab-item
            v-if="
              userData.user.role == 'پذیرش درمانگاه' ||
              userData.user.role == 'پذیرش آزمایشگاه'
            "
          >
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-activity :userId="userId" />
            </v-card>
          </v-tab-item>
          <!-- ساعات کارکرد -->
          <v-tab-item v-if="userData.user.role == 'پذیرش درمانگاه'">
            <v-card class="pa-2" style="min-height: 600px">
              <!-- edit info part has been componented -->
              <person-activity-time :userId="userId" />
            </v-card>
          </v-tab-item>
          <!-- لیست مرخصی‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person leave list part has been componented -->
              <person-leave-list
                :leaves="leavesList"
                @getInfo="showReceptionInfo()"
              />
            </v-card>
          </v-tab-item>

          <!-- لیست پرداخت‌ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person payment part has been componented -->
              <person-payments
                :payments="paymentsList"
                @getInfo="showReceptionInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- لیست دریافت ها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person receives part has been componented -->
              <person-receives
                :receives="receivesList"
                @getInfo="showReceptionInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- تسویه حساب -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <person-checkout
                :userData="userData.user"
                :checkOut="checkOut"
                :job="userData.user.role"
                @getInfo="showReceptionInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- فیش حقوقی -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 650px">
              <!-- person payment part has been componented -->
              <person-pay-slip
                :paySlips="paySlips"
                :userData="userData.user"
                @getInfo="showReceptionInfo()"
              />
            </v-card>
          </v-tab-item>
          <!-- معاینات استخدام -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- person payment part has been componented -->
              <employment-tests :userData="userData.user" />
            </v-card>
          </v-tab-item>
          <!-- لیست فایلها -->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- files part has been componented -->
              <person-files :files="filesList" @getInfo="showReceptionInfo()" />
            </v-card>
          </v-tab-item>

          <!-- تغییر رمز عبور-->
          <v-tab-item>
            <v-card class="pa-2" style="min-height: 600px">
              <!-- change password part has been componented -->
              <person-change-password @getInfo="showReceptionInfo()" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-col>
  </div>
</template>

<script>
const EditPerson = () => import("@/components/hr/EditPerson");
const personLeaveList = () => import("@/components/hr/personLeaveList");
const PersonCheckout = () => import("@/components/hr/PersonCheckout");
const PersonFiles = () => import("@/components/hr/PersonFiles");
const PersonPayments = () => import("@/components/hr/PersonPayments");
const PersonReceives = () => import("@/components/hr/PersonReceives");
const personActivityTime = () => import("@/components/hr/personActivityTime");
const personActivity = () => import("@/components/hr/personActivity");
const personInfo = () => import("@/components/hr/PersonInfo");
const personChangePassword = () => import("@/components/hr/PersonChangePass");
const PersonPaySlip = () => import("@/components/hr/PersonPaySlip");
const EmploymentTests = () => import("@/components/hr/EmploymentTests");

import md5 from "js-md5";
export default {
  components: {
    EditPerson,
    personLeaveList,
    PersonCheckout,
    PersonFiles,
    PersonPayments,
    PersonReceives,
    personActivityTime,
    personActivity,
    personInfo,
    personChangePassword,
    PersonPaySlip,
    EmploymentTests,
  },
  data() {
    return {
      tab: null,
      userData: {},
      password: "",
      repeatPassword: "",
      pageLoaded: false,
      leavesList: [],
      filesList: [],
      deviceType: "",
      checkOut: {},
      userId: "",
      paySlips: [],
    };
  },

  methods: {
    editPassword() {
      //NOTE editting password and send it to server
      if (this.password == this.repeatPassword) {
        this.$http
          .post(
            this.baseUrl + "/clinic/hr/employee/changePassword",
            {
              userId: this.$route.params.id,
              role: "reception",
              newPass: md5(this.password),
            },
            {
              headers: {
                Authorization: "Bearer: " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              this.vLoading = false;
              this.toast("رمز عبور با موفقیت ویرایش شد.", "success");
              this.password = "";
              this.repeatPassword = "";
              this.showReceptionInfo();
            } else {
              this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
              this.vLoading = false;
            }
          })
          .catch((err) => {
            this.toast("خطا: " + err.response.data, "error");
            this.vLoading = false;
          });
      } else {
        this.toast("رمز عبور و تکرار آن باید یکی باشند", "error");
      }
    },
    showReceptionInfo() {
      //NOTE getting all reception info when page mount
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/getInfo",
          {
            userId: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.userData.user = res.data.user;
            this.leavesList = res.data.leaves;
            this.filesList = res.data.files;
            this.paymentsList = res.data.payments;
            this.receivesList = res.data.receives;
            this.checkOut = res.data.checkOut;
            this.paySlips = res.data.payslips;
            this.vLoading = false;
            setTimeout(() => {
              this.pageLoaded = true;
            }, 200);
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
  },

  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    let previousPage = localStorage.getItem("previousPage");
    localStorage.removeItem("previousPage");
    if (previousPage == "newPaySlip") {
      this.tab = 6;
    }
    this.userId = this.$route.params.id;
    this.showReceptionInfo();
  },
};
</script>
<style lang="scss">
.pass-card {
  border-radius: 30px !important;
  background-color: #e6fdff !important;
  box-shadow: 0 10px 5px -10px !important;
  .v-card__title {
    justify-content: center;
    background-color: transparent !important;
  }
}
@media only screen and (max-width: 600px) {
  .v-tabs--vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .v-tab {
    border-bottom: 1px solid #ccc;
  }
  .v-window {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 600px) {
  .v-window {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    margin-bottom: 10px;
  }
}
.v-tabs-slider {
  display: none;
}
</style>
